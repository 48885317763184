import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import "./confirmation-record.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import YourOrder from "../containers/singleInformation/summary/your-order"
import { resetRegistration } from "../redux/registration/registrationAction"

const ConfirmationPaymentPage = () => {
    const dispatch = useDispatch()
    const { ticket, options } = useSelector(({ registrationReducer }) => registrationReducer)

    useEffect(() => {
        return () => {
            dispatch(resetRegistration())
        }
    }, [])

    const getVAT = () => {
        let total = ticket !== null ? parseFloat(ticket.packages_price) : 0

        options.forEach(option => {
            total = total + parseFloat(option.options_price)
        })

        return parseFloat(total).toFixed(2)
    }

    const getTotal = () => {
        let total = ticket !== null ? parseFloat(ticket.packages_price) : 0

        options.forEach(option => {
            total = total + parseFloat(option.options_price)
        })

        return parseFloat(total).toFixed(2)
    }

    return (
        <Layout main={false}>
            <SEO title="CFP 2022 erreur paiement" />
            <Header />
            <div className={"page"}>
                <h2 className={"page-title"}>Votre paiement n'a pas abouti.</h2>
                <p>Mais votre inscription a bien été enregistrée. Vous allez recevoir dans quelques instants un email avec le login et mot de passe de connexion à votre espace personnel sur lequel vous pourrez finaliser votre paiement</p>

                <a href="https://cfp2022.urevent.fr/login" className={"btn btn-info mx-auto mb-4"} >Connectez-vous</a>

                <p>
                    <strong>Le service inscriptions du e-congrès CFP 2022</strong>
                </p>
            </div>
        </Layout>
    )
}

export default ConfirmationPaymentPage
